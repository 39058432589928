
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';
import SecondChanceModal from '../../modals/premium/secondChanceModal.vue';
import * as OM from '@/Model';
import { secondChanceCallback } from '@/afterPremiumActionCallbacks';
import { MatchClient } from '@/services/Services';

@Options({
    components: {
        LoveFaceShadowBar,
    }
})
export default class premiumSecondChanceList extends Vue {

    @Prop() secondChances: OM.SecondChanceSummaryVM[];
    @Prop() refresh: any;

    openSecondChanceModal(id: string) {
        this.$opModal.show(SecondChanceModal, {
            isPremium: true,
            secondChanceId: id,
            acceptUrl: '/new-match/' + id,
            afterPremiumAction: secondChanceCallback(id),
            refreshCallback: () => {
                this.refresh();
            }
        })
    }
}
