
import { Options, Vue } from 'vue-class-component';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';

@Options({
    components: {
        LoveFaceShadowBar,
    }
})
export default class WithoutPremium extends Vue {

}
