
import { ChatClient, MatchClient, NotificationClient, SecondChanceMatchClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';
import { Watch } from 'vue-property-decorator';
import GhostingInformationsModal from '../modals/ghostingInformationsModal.vue';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';
import withoutPremium from '../components/secondChance/withoutPremium.vue';
import withoutPremiumWithList from '../components/secondChance/withoutPremiumWithList.vue';
import notificationIcon from '../components/notificationIcon.vue';
import premiumNoChance from '../components/secondChance/premiumNoChance.vue';
import premiumLimitReached from '../components/secondChance/premiumLimitReached.vue';
import premiumSecondChanceList from '../components/secondChance/premiumSecondChanceList.vue';
import store from '@/store';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import InfoModal from '@/components/modals/infoModal.vue';
import SecondChanceModal from '../modals/premium/secondChanceModal.vue';
import { secondChanceCallback } from '@/afterPremiumActionCallbacks';
import { WSSServices } from '@/services/WSSServices';
import { CommonServices } from '@/services/CommonServices';

@Options({
    components: {
        LoveFaceShadowBar,
        notificationIcon,
        withoutPremiumWithList,
        withoutPremium,
        premiumNoChance,
        premiumLimitReached,
        premiumSecondChanceList
    }
})
export default class Affinity extends Vue {

    chats: OM.ChatRoomListVM[] = [];
    secondChance: OM.SecondChanceVM = new OM.SecondChanceVM();
    loggedUserIdentifier: string = "";
    notifications: { [key: string]: number; } = {};
    loaded: boolean = false;
    ghostingLevel = OM.GhostingLevel;

    chatRoomIds: string[] = [];
    deletingMode: boolean = false;
    pressTimer: any = null;
    startTimeMS: number = 0;

    created() {
        store.state.openSecondChanceCb = this.openSecondChanceCb;
        this.loggedUserIdentifier = StorageServices.getLoggedUser().identifier;
        
        // store.state.onAffinityNotificationReceivedInAffinitySection = (message: VM.FCMNotificationVM) => {
        //     console.log(message)

        //     var found = this.chats.find( x => x.chatRoomIdentifier == message.chatIdentifier);

        //     if(!found){
        //         this.init(false);
        //         return;
        //     }
        //     // this.init(false);
        // };

        this.onAffinityCreated();        
        this.init(true);
    }

    onAffinityCreated(){
        document.addEventListener("resume", this.resumeCallback, false);
        document.addEventListener("pause", this.pauseCallback, false);
    }

    resumeCallback(){
        this.init();
    }

    pauseCallback(){
        WSSServices.disconnectAffinity(this.onMessageReceive, this.onTakeOffAffinity, this.onNewAffinity);
    }

    init(showSpinner = false) {
        WSSServices.connectAffinity(this.onMessageReceive, this.onTakeOffAffinity, this.onNewAffinity);

        Promise.all([
            ChatClient.getAllChatRooms(showSpinner),
            NotificationClient.getAffinityNotification(showSpinner),
            SecondChanceMatchClient.getSecondChance(showSpinner)
        ])
        .then(x => {
            this.chats = x[0];
            this.notifications = x[1];
            this.secondChance = x[2];
            this.loaded = true;
        })
    }

    get orderedChats(){
        return this.chats.sort((a, b) => {
            var dateA = a.lastMessage ? a.lastMessage.utcDatetime : a.createdOn;
            var dateB = b.lastMessage ? b.lastMessage.utcDatetime : b.createdOn;
            
            console.log(dateA, dateB)
            return new Date(dateB).getTime() - new Date(dateA).getTime();
        });
    }

    onMessageReceive(val: string) {
        var chatMex = JSON.parse(val) as OM.ChatMessageVM;

        var notificationKeys = Object.keys(this.notifications);
        if(notificationKeys.indexOf(chatMex.chatRoomIdentifier) != -1)
            this.notifications[chatMex.chatRoomIdentifier] ++;
        else 
            this.notifications[chatMex.chatRoomIdentifier] = 1;

        var found = this.chats.find( x => x.chatRoomIdentifier == chatMex.chatRoomIdentifier);

        if(found)
            found.lastMessage = chatMex;
    }

    onTakeOffAffinity(chatRoomIdentifier: string) {
        var found = this.chats.find( x => x.chatRoomIdentifier == chatRoomIdentifier);

        if(!found)
            return;

        var index = this.chats.indexOf(found);
        this.chats.splice(index, 1);
    }

    onNewAffinity(val: string) {
        var chatroom: OM.ChatRoomListVM = JSON.parse(val);

        var found = this.chats.find( x => x.chatRoomIdentifier == chatroom.chatRoomIdentifier);

        if(found)
            return;

        this.chats.push(chatroom);
    }

    openSecondChanceCb(secondChanceIdentifier: string){
        this.$opModal.show(SecondChanceModal, {
            secondChanceId: secondChanceIdentifier,
            acceptUrl: '/new-match/' + secondChanceIdentifier,
            isPremium: true,
            afterPremiumAction: secondChanceCallback(secondChanceIdentifier),
            refreshCallback: () => {
                this.init();
            }
        })
    }
    
    startTimer(chatRoomId: string) {
        if(this.deletingMode)
            return;

        this.startTimeMS = (new Date()).getTime();

        clearTimeout(this.pressTimer);
        this.pressTimer = setTimeout(() => {
            // Run function
            this.deletingMode = true;

            if(this.chatRoomIds.length == 0)
                this.manageChatRoomIds(chatRoomId);
            
            this.pressTimer = null;
        }, 650)
    }

    cancelTimer() {
        if(this.pressTimer){
            clearTimeout(this.pressTimer);
        }
    }

    goToChat(chatRoomId: string){
        if(this.deletingMode){
            this.manageChatRoomIds(chatRoomId);
            return;
        }

        this.$router.push("/chats/" + chatRoomId);
    }

    scrolled(){
        clearTimeout(this.pressTimer);
    }

    manageChatRoomIds(chatRoomId: string) {
        var indexOf = this.chatRoomIds.indexOf(chatRoomId);
        if(indexOf == -1)
            this.chatRoomIds.push(chatRoomId);
        else
            this.chatRoomIds.splice(indexOf, 1);
    }

    @Watch("chatRoomIds.length")
    exitDeletingMode() {
        if(this.chatRoomIds.length == 0) {
            this.deletingMode = false;

            if (this.pressTimer !== null) {
                clearTimeout(this.pressTimer)
                this.pressTimer = null;
            }
        }
    }

    checkIfSelectedForDeleting(chatRoomId: string) {
        return this.chatRoomIds.indexOf(chatRoomId) > -1;
    }

    openGhostingInformations() {
        this.$opModal.show(GhostingInformationsModal, {});
    }

    getUnreadMessage(identifier: string){
        var keys = Object.keys(this.notifications);

        if(keys.indexOf(identifier) == -1)
            return 0;
            
        return this.notifications[identifier];
    }

    beforeUnmount(){
        this.pauseCallback();
        document.removeEventListener("resume", this.resumeCallback, false);
        document.removeEventListener("pause", this.pauseCallback, false);
    }

    unmounted(){
        store.state.openSecondChanceCb = null;
    }

    takeOffAffinities() {
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_EliminateAffinitaNonPossonoEssereRecuperateVuoiProcedere?", "Una volta eliminate le affinità, non potranno più essere recuperatate. <br />Vuoi procedere?"),
            confirm: this.$localizationService.getLocalizedValue("app_Si", "Si"),
            deny: this.$localizationService.getLocalizedValue("app_No", "No"),
            confirmCb: () => {
                CommonServices.showSpinner();

                WSSServices.takeOffMultipleAffinity(this.chatRoomIds)
                .then( () => {
                    this.$opModal.closeAll();
                    this.$opModal.show(InfoModal, {
                        img: "feven_squared_violet.svg",
                        text: this.$localizationService.getLocalizedValue("app_takeOffAffinitiesSuccess", "Affinities removed with success"),
                        confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                        confirmCb: () => {
                            this.$opModal.closeAll();

                            this.chatRoomIds.forEach(chatRoomIdentifier => {
                                this.onTakeOffAffinity(chatRoomIdentifier);
                            });
                            
                            this.chatRoomIds = [];
                        }
                    })
                }).catch( err => {
                    this.$opModal.show(InfoModal, {
                        img: "face_error.svg",
                        text: err.Message,
                        confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                        confirmCb: () => {
                            this.$opModal.closeLast();
                        }
                    })
                }).finally( () => {
                    CommonServices.hideSpinner();
                })
            },
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }
}
