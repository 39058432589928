
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {
    }
})
export default class Finder extends Vue {

    created(){
    }

    mounted(){
    }   

    get notificationNumber(){
        return this.$store.state.notificationNumber;
    }
}
