
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {
    }
})
export default class premiumLimitReached extends Vue {

}
