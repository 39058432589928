
import { Options, Vue } from 'vue-class-component';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import SecondChanceModal from '../../modals/premium/secondChanceModal.vue';
import { secondChanceCallback } from '@/afterPremiumActionCallbacks';
import { MatchClient } from '@/services/Services';

@Options({
    components: {
        LoveFaceShadowBar,
    }
})
export default class WithoutPremiumWithList extends Vue {

    @Prop() secondChances: OM.SecondChanceSummaryVM[];
    @Prop() refresh: any;

    openSecondChanceModal(id: string) {
        this.$opModal.show(SecondChanceModal, {
            secondChanceId: id,
            acceptUrl: '/new-match/' + id,
            afterPremiumAction: secondChanceCallback(id),
            refreshCallback: () => {
            }
        })
    }
}
